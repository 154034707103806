import React from "react";

import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";

const SecondPage = () => {
  return (
    <Layout>
      <Seo title="Datenschutzerklärung" />
      <h1>Datenschutzerklärung</h1>

      <h2>1. Haftungsbeschränkung</h2>
      <p>
        Die Webseite wurde mit größtmöglicher Sorgfalt erstellt. Der Anbieter
        dieser Webseite übernimmt dennoch keine Gewähr für die Richtigkeit,
        Vollständigkeit und Aktualität der bereitgestellten Inhalte und
        Informationen. Die Nutzung der Webseiteninhalte erfolgt auf eigene
        Gefahr. Allein durch die Nutzung der Website kommt keinerlei
        Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande.
      </p>
      <h2>2. Verlinkungen</h2>
      <p>
        Die Webseite enthält Verlinkungen zu anderen Webseiten (externe Links).
        Diese Webseiten unterliegen der Haftung der jeweiligen Seitenbetreiber.
        Bei Verknüpfung der externen Links waren keine Rechtsverstöße
        ersichtlich. Auf die aktuelle und künftige Gestaltung der verlinkten
        Seiten hat der Anbieter keinen Einfluss. Die permanente überprüfung der
        externen Links ist für den Anbieter ohne konkrete Hinweise auf
        Rechtsverstöße nicht zumutbar. Bei Bekanntwerden von Rechtsverstößen
        werden die betroffenen externen Links unverzüglich gelöscht.
      </p>
      <h2>3. Urheberrecht / Leistungsschutzrecht</h2>
      <p>
        Die auf dieser Webseite durch den Anbieter veröffentlichten Inhalte
        unterliegen dem deutschen Urheberrecht und Leistungsschutzrecht. Alle
        vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene
        Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters
        oder jeweiligen Rechteinhabers. Dies gilt vor allem für
        Vervielfältigung, Bearbeitung, übersetzung, Einspeicherung, Verarbeitung
        bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen
        Medien und Systemen. Dabei sind Inhalte und Rechte Dritter als solche
        gekennzeichnet. Das unerlaubte Kopieren der Webseiteninhalte oder der
        kompeltten Webseite ist nicht gestattet und strafbar. Lediglich die
        Herstellung von Kopien und Downloads für den persönlichen, privaten und
        nicht kommerziellen Gebrauch ist erlaubt. Diese Website darf ohne
        schriftliche Erlaubnis nicht durch Dritte in Frames oder iFrames
        dargestellt werden.
      </p>
      <h2>4. Datenschutz</h2>
      <p>
        Mit dem Besuch dieser Webseite können Informationen über den Zugriff
        gespeichert werden. Diese Daten wie Uhrzeit, Datum und die betrachtete
        Seite gehören nicht zu den personenbezogenen Daten, sondern sind
        anonymisiert. Diese werden nur aus statistischen Zwecken erfasst. Eine
        Weitergabe an Dritte, zu kommerziellen oder nichtkommerziellen Zwecken,
        erfolgt nicht. Der Anbieter weist ausdrücklich darauf hin, dass die
        Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
        Sicherheitslücken aufweisen und nicht lückenlos vor dem Zugriff durch
        Dritte geschützt werden kann. Die Verwendung der Kontaktdaten des
        Impressums zur gewerblichen Werbung ist ausdrücklich nicht erwünscht, es
        sei denn der Anbieter hatte zuvor seine schriftliche Einwilligung
        erteilt oder es besteht bereits eine Geschäftsbeziehung. Der Anbieter
        und alle auf dieser Website genannten Personen widersprechen hiermit
        jeder kommerziellen Verwendung und Weitergabe ihrer Daten.
      </p>
    </Layout>
  );
};

export default SecondPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
